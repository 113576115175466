import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { formatMonth, isThisMonth, isToday, formatTimeRange } from '../utils/FormatDates'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck } from '@fortawesome/free-regular-svg-icons'
import { faCircleUser } from '@fortawesome/free-solid-svg-icons'
import '../styles/Navigation.css'

const Navigation = ({ navRef, startTimestamp, feedRef, days, currentDayRef, months, scrollToDay, scrollToToday, disableFeedScroll, resetFeedScroll, handleNavigationIntro }) => {

    //Basic Navigation
    //const navRef = useRef(null); //Holds entire nav (passed through)
    const monthsRef = useRef(null); //Displays the current month

    //Expanded Navigation
    const weekContainerRef = useRef(null); //Contains the drop down swipable week navigation
    const weekTrayRef = useRef(null); //Sliding tray holds all the weeks
    const startXRef = useRef(0); // Starting X position of the touch
    const trayStartXRef = useRef(0); // Initial translateX value of the tray
    const weekContainerWidth = useRef(0); // Width of the week container
    const currentIndexRef = useRef(0); // Keeps track of the current visible week
    const weeksMap = useRef();

    //const [swipingActive, setSwipingActive] = useState(false);
    const swipingActiveRef = useRef(false);
    const [expanded, setExpanded] = useState(false);

    /* Init navigation on months data */
    useEffect(() => {
        positionMonths();

        if (navRef.current) {
            navRef.current.addEventListener("animationend", handleAnimationEnd);
        }

        return () => {
            if (navRef.current) {
                navRef.current.removeEventListener("animationend", handleAnimationEnd);
            }
        };
    }, [months]);

    /* Months move as user scrolls up and down */
    useEffect(() => {
        window.addEventListener('scroll', positionMonths);

        return () => {
            window.removeEventListener('scroll', positionMonths);
        };
    }, [feedRef.current]);

    /* Handler for navigation intro */
    const handleAnimationEnd = (event) => {
        if (event.animationName === "expandToPill") { // Check if all animations have completed
            if (handleNavigationIntro) { 
                handleNavigationIntro();
            }
        }
    };

    /* Positions the relevant month in the navigation */
    function positionMonths() {
        const nav = navRef.current;
        const months = monthsRef.current;
        const feedContainer = feedRef.current;

        if (!nav || !months || !feedContainer) return;

        const containerHeight = feedContainer.clientHeight;
        const viewportHeight = window.innerHeight;

        if (months.children && months.children.length > 0) {
            // If container is not tall enough to force a scroll, hide all but top month
            if (containerHeight <= viewportHeight) {
                months.children[0].style.opacity = 1;
                return;
            } else if (months.children[0].style.opacity == 0) {
                for (let j = 0; j < months.children.length; j++) {
                    months.children[j].style.opacity = 1;
                }
            }
        }

        const monthContainers = feedContainer.querySelectorAll('.month-container');
        const navRect = nav.getBoundingClientRect();

        for (let i = 0; i < monthContainers.length; i++) {
            const container = monthContainers[i];
            const feedMonth = container.querySelector('.month-title');

            const restingPoint = 0;
            const monthRect = container.getBoundingClientRect();
            let offsetY = monthRect.top - navRect.top;


            const isExpanded = navRef.current.classList.contains('expand');
            if (isExpanded) {
                offsetY = offsetY - 102; //Magic number for expand
            }

            const offsetYPlusHeight = -(monthRect.height) - offsetY + 14; //magic number for contracted

            if (offsetY < restingPoint && offsetYPlusHeight < restingPoint) {
                offsetY = restingPoint;
                feedMonth.style.opacity = 0;
            } else if (offsetYPlusHeight >= restingPoint) {
                offsetY = -(offsetYPlusHeight);
                feedMonth.style.opacity = 0;
            } else if (offsetY >= restingPoint) {
                feedMonth.style.opacity = 1;
            }

            if (i == 0 && offsetY > 0) {
                offsetY = 0;
            }

            const monthTitle = months.children[i];
            monthTitle.style.transform = `translateY(${offsetY}px)`;
        }
    }

    const handleTouchStart = (e) => {
        // disable feed scrolling
        disableFeedScroll();

        // Sets the width setting for the nav slider
        setupWeeksNav();

        // Capture the starting position of the swipe
        startXRef.current = e.touches[0].clientX;

        // Get the starting translate X from the weekTrayRef
        const matrix = getComputedStyle(weekTrayRef.current).transform;
        trayStartXRef.current = matrix !== 'none' ? parseFloat(matrix.split(',')[4]) : 0;
    };

    const handleTouchMove = (e) => {

        // Calculate the horizontal swipe distance
        const deltaX = e.touches[0].clientX - startXRef.current;

        // Apply the translateX value based on the swipe movement
        weekTrayRef.current.style.transform = `translateX(${trayStartXRef.current + deltaX}px)`;
    };

    const handleTouchEnd = (e) => {
        resetFeedScroll();

        // Calculate the current position of the tray
        const matrix = getComputedStyle(weekTrayRef.current).transform;
        const currentTranslateX = matrix !== 'none' ? parseFloat(matrix.split(',')[4]) : 0;

        // Calculate the swipe distance as a percentage of the container width
        const swipeDistance = currentTranslateX - trayStartXRef.current;
        const swipePercentage = Math.abs(swipeDistance / weekContainerWidth.current);

        // Check if the swipe is beyond the 20% threshold
        if (swipePercentage > 0.25) {
            if (swipeDistance < 0 && currentIndexRef.current < weekTrayRef.current.children.length - 1) {
                currentIndexRef.current++; // Swipe left
            } else if (swipeDistance > 0 && currentIndexRef.current > 0) {
                currentIndexRef.current--; // Swipe right
            }

            const weekDiv = weekTrayRef.current.children[currentIndexRef.current];
            if (weekDiv) {
                const dataValue = weekDiv.getAttribute('data-week');
                const weekData = weeksMap.current[dataValue];
                const firstDayWithEvent = weekData.days.find((day) => day.hasEvent);
                swipingActiveRef.current = true;
                scrollToDay(firstDayWithEvent.date);
            }
        }

        // Snap to the calculated week
        moveWeeksSlider(currentIndexRef.current);
    };


    const toggleNavExpand = () => {
        const isInitiallyCollapsed = !navRef.current.classList.contains('expand');
        if (isInitiallyCollapsed) {
            setupWeeksNav();
            slideToWeek(moment(startTimestamp).format('YYYY-MM-DD'), true);
        }

        navRef.current.classList.toggle('expand');

        const isExpanded = navRef.current.classList.contains('expand');
        const translateY = isExpanded ? "120px" : "0px";
        feedRef.current.style.transition = 'transform 0.2s ease-out';
        feedRef.current.style.transform = `translateY(${translateY})`;

        // Remove the transition after snapping
        feedRef.current.addEventListener(
            'transitionend',
            () => {
                feedRef.current.style.transition = '';
                setupWeeksNav();
                scrollToDay(moment(startTimestamp).format('YYYY-MM-DD'));
                slideToWeek(moment(startTimestamp).format('YYYY-MM-DD'));
            },
            { once: true }
        );
    }


    const slideToWeek = (targetDate, noAnimation) => {
        if (!weekTrayRef.current || !weeksMap.current || Object.keys(weeksMap.current).length === 0) return;

        const { targetWeekKey, firstDayWithEvent } = findWeekAndFirstDay(targetDate) || {};
        if (!targetWeekKey) return;

        // Get the target week index
        const targetIndex = Array.from(weekTrayRef.current.children).findIndex(
            (child) => child.getAttribute('data-week') === targetWeekKey
        );

        if (targetIndex === -1) {
            console.warn("Target week not found in weekTrayRef children.");
            return;
        }

        // Update the current index ref
        currentIndexRef.current = targetIndex;

        // Apply the snap animation
        moveWeeksSlider(targetIndex, noAnimation);
    };

    // Helper function: Apply snap animation
    const moveWeeksSlider = (targetIndex, noAnimation) => {
        const snapPosition = -targetIndex * weekContainerWidth.current;

        // Set the new position
        weekTrayRef.current.style.transform = `translateX(${snapPosition}px)`;

        const isExpanded = navRef.current.classList.contains('expand');

        // Apply the slide animation
        if (isExpanded && !noAnimation) {
            weekTrayRef.current.style.transition = 'transform 0.2s ease-out';

            // Remove the transition after snapping
            weekTrayRef.current.addEventListener(
                'transitionend',
                () => {
                    weekTrayRef.current.style.transition = '';
                },
                { once: true }
            );
        }
    };

    // Helper function: Find the week and first day with an event
    const findWeekAndFirstDay = (targetDate) => {
        const targetWeekKey = Object.keys(weeksMap.current).find((weekKey) => {
            const weekData = weeksMap.current[weekKey];
            return weekData.days.some((day) => day.date === targetDate); // Match the date
        });

        if (!targetWeekKey) {
            console.warn("Date does not belong to any week in weeksMap.");
            return null;
        }

        const weekData = weeksMap.current[targetWeekKey];
        const firstDayWithEvent = weekData.days.find((day) => day.hasEvent);

        return { targetWeekKey, firstDayWithEvent };
    };

    const setupWeeksNav = () => {
        // Set the width of the weekContainer
        weekContainerWidth.current = weekContainerRef.current.offsetWidth;
    }

    const mapWeeks = () => {
        weeksMap.current = {};
        // Iterate over each day and its events
        [...days.entries()].forEach(([day, events]) => {
            // Get the week number and year for grouping by week
            const weekKey = moment(day).startOf('week').format('YYYY-WW'); // e.g., "2023-12" for week 12 of 2023

            // If this week doesn't exist in weeksMap, initialize it
            if (!weeksMap.current[weekKey]) {
                weeksMap.current[weekKey] = {
                    days: Array(7).fill(null).map((_, i) => ({
                        date: moment(day).startOf('week').add(i, 'days').format('YYYY-MM-DD'),
                        hasEvent: false
                    })),
                    weekEvents: []
                };
            }

            // Filter out events with monthEvent: true
            const nonMonthEvents = events.filter(event => !event.monthEvent);

            // If there are any remaining events, mark the day as having events
            if (nonMonthEvents.length > 0) {
                const dayIndex = moment(day).weekday(); // 0 for Sunday, 6 for Saturday
                weeksMap.current[weekKey].days[dayIndex].hasEvent = true;

                // Optionally, add these events to the weekEvents array if needed
                weeksMap.current[weekKey].weekEvents.push(...nonMonthEvents);
            }

            // Add all events with weekEvent property to weekEvents array
            const weekEvents = events.filter(event => event.weekEvent);
            if (weekEvents.length) {
                weeksMap.current[weekKey].weekEvents.push(...weekEvents);
            }
        });

        //console.log(weeksMap.current);
    };

    const renderWeeks = () => {
        return Object.keys(weeksMap.current).map((weekKey) => {
            const week = weeksMap.current[weekKey]; // Get the week object
            const weekEvents = week.weekEvents;

            return (
                <div className='week-details' key={weekKey} data-week={weekKey}>
                    <div className="week">
                        {week.days.map((day) => (
                            <button
                                key={day.date}
                                className={`day ${day.hasEvent ? 'events' : 'noclick'}`}
                                data-day={day.date}
                                {...(day.hasEvent && { onClick: (e) => { e.preventDefault(); e.stopPropagation(); scrollToDay(day.date) } })}
                            >
                                <span
                                    className={`label`}
                                /*TODO - Make nav a component - then highlight active className={`label ${activeDayId === day.date ? 'active' : ''}`} */
                                >
                                    {moment(day.date).format('ddd').toLowerCase()}
                                </span>
                                <div className={`date ${isToday(day.date)}`}>
                                    {moment(day.date).format('D')}
                                </div>
                            </button>
                        ))}
                    </div>
                    {
                        /*
                            <div className='week-events-container'>
                                <span className='label'>
                                <FontAwesomeIcon className="icon" icon={faCalendarWeek} /> Full week events..
                                </span>
                                {weekEvents.map((event, index) => (
                                <div
                                    key={index}
                                    className={`week-event ${event.firstView ? 'firstview' : ''}`}
                                    onClick={(e) => {
                                    e.preventDefault();
                                    openEvent(event._id);
                                    }}
                                >
                                    {event.eventName}
                                </div>
                                ))}
                            </div>
                        */
                    }
                </div>
            );
        });
    };

    const renderMonths = () => {
        return Array.from(months.entries()).map(([monthKey, monthData]) => (
            <div key={monthKey} className={isThisMonth(monthKey, startTimestamp) ? 'month-title today' : 'month-title'}>
                {formatMonth(monthKey, startTimestamp)}
            </div>
        ));
    }

    const renderNav = useCallback(() => {
        // mapWeeks();
        return (
            <div className='nav' ref={navRef}>
                <div className='top'>
                    <button className='go-today' onClick={(e) => { scrollToToday() }}>
                        <FontAwesomeIcon className="icon" icon={faCalendarCheck} />
                        <span className='label'>Today</span>
                    </button>
                    <div className='months-container' ref={monthsRef}>
                        {renderMonths()}
                    </div>
                    <Link className='profile' to="/profile">
                        <button>
                            <FontAwesomeIcon className="icon" icon={faCircleUser} />
                        </button>
                    </Link>
                </div>
                <div className='divider'></div>
                <div className='bottom'>
                    <div className='week-container'
                        ref={weekContainerRef}
                        onTouchStart={handleTouchStart}
                        onTouchMove={handleTouchMove}
                        onTouchEnd={handleTouchEnd}>

                        <div className='week-tray'
                            ref={weekTrayRef}>
                            { /*renderWeeks() */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }, [months]);



    return (<>{(months && months.size > 0) && renderNav()}</>);

}


export default Navigation;