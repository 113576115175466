import React, { useState, useEffect } from 'react';
import WatchlistComponent from '../components/WatchlistComponent';

const JoinWatchlist = () => {
  const [advance, setAdvance] = useState(false);

  const handleAdvanceChange = (newAdvance) => {
    setAdvance(newAdvance);
  };


  return (
    <div className="join-watchlist">
      <div className="header">
        <h2>Setup your Addresses</h2>
        <div className='explain'>Only messages from these addresses <br/>are used for your schedule.</div>
      </div>
      <WatchlistComponent onAdvanceChange={handleAdvanceChange} />
      <div className='note'>Add senders that email you about school and sports</div>
      <button className='continue' disabled={!advance} onClick={() => { window.location.href = '/join/success' }}>Continue</button>
    </div>
  );
};

export default JoinWatchlist;
