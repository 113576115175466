import React, { useMemo, useState, useEffect, useContext, useRef, useCallback } from 'react';

import { FeedContext } from './FeedContext';
import moment from 'moment';
import { getFirstTwoInitials, getColors } from '../utils/FormatUtils';
import { formatMonth, isThisMonth, isToday, formatTimeRange } from '../utils/FormatDates'
import { getWeatherIcon } from '../utils/FormatWeather'
import CheckMessages from './CheckMessages';
import EventIconLabel from './EventIconLabel';
import Navigation from './Navigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarCheck, faCalendarDays, faSun } from '@fortawesome/free-regular-svg-icons'
import { faArrowCircleLeft, faChevronRight, faCircleChevronRight, faCloud, faCloudRain } from '@fortawesome/free-solid-svg-icons'

import 'weather-icons/css/weather-icons.css';

const FocusFeed = ({ openEvent, closeEvent, openMessage, closeMessage }) => {
  const { userProfile, startTimestamp, days, months, monthEvents, loadComplete, currentWeather, weatherForecast, demoMode, setDemoMode } = useContext(FeedContext);

  const demoComplete = useRef(null);
  const eventCoachmark = useRef(null);

  const feedContainerRef = useRef(null);
  const navRef = useRef(null);
  const attentionMarkerRef = useRef(null)
  const navIntroComplete = useRef(false);
  const [navComplete, setNavComplete] = useState(false);

  // Magic numbers for nav
  const offset = -56; //Magic number for closed nav
  const expandedOffset = -144; //Magic number for open nav

  const currentDayRef = useRef(null);

  // Observers 
  const mutationObserver = useRef(null);
  const intersectionObserver = useRef(null);

  useEffect(() => {
    init();
    return () => {
      destroy();
    };
  }, []);

  const init = () => {
    console.log('FocusFeed: init');
    // Add resize listener
    window.addEventListener('resize', handleResize);

    mutationObserver.current = new MutationObserver(() => {
      setupFeedIntersectionObserver();
      scrollToToday();
    });

    if (feedContainerRef.current) {
      mutationObserver.current.observe(feedContainerRef.current, { childList: true, subtree: true });
    }

  }

  const destroy = () => {
    console.log('FocusFeed: destroy');
    if (mutationObserver.current) {
      mutationObserver.current.disconnect();
    }
    window.removeEventListener('resize', handleResize);
  }

  const handleResize = () => {
    console.log('FocusFeed: handleResize')
    setupFeedIntersectionObserver();
  };

  const exitDemo = (wait) => {
    closeEvent();
    closeMessage();
    if (wait) {
      console.log('wait')
      setTimeout(() => { setDemoMode(false); }, 500);
    } else {
      setDemoMode(false);
    }
  }

  /* Navigation has done initial animation in and is correct size/position */
  const handleNavigationIntro = () => {
    console.log('handleNavigationIntro');
    navIntroComplete.current = true;
    setNavComplete(true);
    if (attentionMarkerRef.current) {
      attentionMarkerRef.current.classList.add('show');
    }
    setupFeedIntersectionObserver();
  };

  const setupFeedIntersectionObserver = () => {
    if (!navIntroComplete.current || !navRef.current) {
      return;
    }
    console.log('FocusFeed: setupFeedIntersectionObserver');

    const navRect = navRef.current.getBoundingClientRect();
    const topPadding = 10;
    const markerTop = topPadding + navRect.top + navRect.height // Padding
    const markerHeight = 50;
    const markerBottom = window.innerHeight - (markerTop + markerHeight);

    attentionMarkerRef.current.style.top = `${markerTop}px`;
    attentionMarkerRef.current.style.height = `${markerHeight}px`;

    const rootMargin = `-${markerTop}px 0px -${markerBottom}px 0px` // Adjust the bottom value dynamically

    // Clean up the previous observer
    if (intersectionObserver.current) {
      intersectionObserver.current.disconnect();
    }

    // Create a new IntersectionObserver with the updated rootMargin
    intersectionObserver.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Get the parent of the current `.day` element
            const parentContainer = entry.target.closest('.day-container');
            if (parentContainer?.id && parentContainer.id !== currentDayRef.current) {
              currentDayRef.current = parentContainer.id;
              //console.log(parentContainer.id);
              //if (!nav.isSwipingActive) {
              //  nav.slideToWeek(parentContainer.id);
              //}
            }
          }
        });
      },
      {
        root: null, // Use the viewport as the root
        rootMargin: rootMargin,
        threshold: 0, // Trigger as soon as even one pixel is visible within the slice
      }
    );

    // Use a specific selector to target `.day` elements inside `.day-container`
    const dayElements = document.querySelectorAll(".feed-container .day-container .day");
    dayElements.forEach((el) => intersectionObserver.current.observe(el));
  }

  /* Feed has been tapped */
  const handleFeedTouchStart = (e) => {
    //nav.isSwipingActive.current = false;
  }

  /* Scroll to "today" */
  const scrollToToday = () => {
    scrollToDay(moment(startTimestamp).format('YYYY-MM-DD'));
  }

  /* Scroll to a specific day. Should be in YYYY-MM-DD format */
  const scrollToDay = (day) => {
    const targetDay = document.querySelector(`[id="${day}"]`);
    if (!targetDay) {
      return;
    }

    const element = targetDay;
    const elementRect = element.getBoundingClientRect();
    const topPosition = elementRect.top + window.scrollY + offset //(nav.isExpanded ? expandedOffset : offset);

    // Scroll to the calculated position
    window.scrollTo({
      top: topPosition,
      behavior: 'smooth'
    });
  };

  // Helper function: Disable body scroll
  const disableFeedScroll = () => {
    document.body.style.overflow = 'hidden'; // Disable body scroll
    document.body.style.touchAction = 'none';
    document.body.style.pointerEvents = 'none';
  };

  // Helper function: Reset styles for body and container
  const resetFeedScroll = () => {
    document.body.style.overflow = 'auto'; // Re-enable body scroll
    document.body.style.touchAction = 'auto';
    document.body.style.pointerEvents = 'auto';
  };

  const renderEventCreators = (event) => {
    if (!event || event.mentions.length == 0) {
      return;
    }
    const initials = getFirstTwoInitials(event.mentions[0].sourceSender);
    const colors = getColors(initials);

    return (
      <span
        className="count"
        style={event.firstView
          ? {
            background: `linear-gradient(100deg, ${colors.primary} 0%, ${colors.tertiary} 70%, ${colors.quaternary} 100%)`,
            border: `1px solid ${colors.secondary}`
          }
          : {}} >
        {initials}
        {event.mentions.length > 1 ? ` +${event.mentions.length - 1}` : ''}
      </span>
    )
  }

  const setEventVisibilityClassName = (events, event) => {
    // Check if the event has dislike set to true
    if (event.dislike) {
      return ' dislike'; // Return "dislike" if the event is disliked
    }

    // If there are no related events, return an empty string (visible)
    return '';
  };

  const showTap = (element) => {
    element.classList.add('tap'); // Add the 'tap' class
    setTimeout(() => {
      element.classList.remove('tap'); // Remove the 'tap' class after 0.2 seconds
    }, 200);

    if (demoMode) {
      eventCoachmark.current.classList.add('hide');
      demoComplete.current.classList.add('show');
    }
  }

  const celsiusToFahrenheit = (celsius) => {
    return Math.round((celsius * 9) / 5 + 32);
  }

  const forecast = (day) => {

    if (isToday(day) && userProfile && currentWeather) {
      const forecast = {}
      if (currentWeather.metadata.units == 'm' && userProfile.preferences.tempUnits == 'f') {
        forecast.temp = celsiusToFahrenheit(currentWeather.temperature) + '°';
      } else {
        forecast.temp = Math.round(currentWeather.temperature) + '°';
      }
      forecast.symbol = getWeatherIcon(currentWeather.conditionCode);
      return forecast;
    }

    const tomorrow = moment().add(1, "days"); // Get tomorrow's date
    const tenDaysFromNow = moment().add(10, "days"); // Calculate 10 days from today
    const targetDate = moment(day); // Convert the given date to a Moment object

    // Check if the target date is within the forecast window
    const inForecastWindow = targetDate.isSameOrAfter(tomorrow, "day") && targetDate.isSameOrBefore(tenDaysFromNow, "day");

    if (inForecastWindow && weatherForecast) {
      // Find the forecast for the target day
      const targetForecast = weatherForecast.days.find((forecastDay) => {
        const forecastStart = moment(forecastDay.forecastStart);
        const forecastEnd = moment(forecastDay.forecastEnd);
        return targetDate.isBetween(forecastStart, forecastEnd, "day", "[]"); // Inclusive range
      });

      if (targetForecast) {
        // Extract temperature and condition code
        const temperature = targetForecast.daytimeForecast.temperatureMax;
        const conditionCode = targetForecast.daytimeForecast.conditionCode;

        // Build the forecast object
        const forecast = {};
        if (weatherForecast.metadata.units === 'm' && userProfile.preferences.tempUnits === 'f') {
          forecast.temp = celsiusToFahrenheit(temperature) + '°';
        } else {
          forecast.temp = Math.round(temperature) + '°';
        }

        // Get the weather icon for the condition code
        forecast.symbol = getWeatherIcon(conditionCode);

        return forecast; // Return the processed forecast
      } else {
        console.log("No matching forecast day found");
        return null;
      }
    } else {
      return null;
    }

  }

  const dayContainer = useCallback((day, events) => {
    const weather = forecast(day);

    return (
      <div className="day-container" id={moment(day).format('YYYY-MM-DD')}>
        <div
          className={`day ${isToday(day)}`}
        >
          <div className='ddd'>{moment(day).format('ddd')}</div>
          <div className='MMDD'>
            <span className='DD'>{moment(day).format('D')}</span>
          </div>
          {weather && (
            <div className="weather">
              <div className={`symbol wi ${weather.symbol}`}>
              </div>
              <div className="temp">
                {weather.temp}
              </div>
            </div>
          )}
        </div>
        <div className="event-container">
          {events.length > 0 ? (
            events.map((event, index) => (
              <div className={`event${setEventVisibilityClassName(events, event)}${event.firstView ? ' firstview' : ''}${event.tentative ? ' tentative' : ''}`}
                key={index}
                onClick={(e) => {
                  e.preventDefault();
                  showTap(e.currentTarget);
                  openEvent(event._id);
                }}>
                <div className="timing">
                  {formatTimeRange(event)}
                </div>
                <div className="title">
                  {event.eventName}
                </div>
                <div className='message'>
                  <a onClick={(e) => { e.preventDefault(); e.stopPropagation(); openMessage(event.mentions[0].sourceMessageId); }} href="/">
                    {renderEventCreators(event)}
                  </a>
                </div>
                <EventIconLabel firstView={event.firstView} eventName={event.like ? 'like' : event.eventName} includeLabel={false} />
                {demoMode && (
                  <div className="coachmark" ref={eventCoachmark}>
                    <FontAwesomeIcon className="icon left" icon={faArrowCircleLeft} />
                    <div className="instruction">
                      Tap event for details
                    </div>
                  </div>
                )}
              </div>
            ))
          ) : (
            <div className="event none">
              <div className="timing" >&nbsp;</div>
              <div className="title">
                No Events <br />Today
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }, [demoMode, currentWeather, weatherForecast]);

  const renderMonthEvents = (monthKey) => {
    return (
      <div className='month-events-container'>
        <div className='month'>
          <FontAwesomeIcon className="icon" icon={faCalendarDays} />
        </div>
        <div className="event-container">
          {monthEvents.get(monthKey)?.map((event, index) => (
            <div
              className={`event${event.firstView ? ' firstview' : ''}`}
              key={event._id}
              onClick={(e) => {
                e.preventDefault();
                showTap(e.currentTarget);
                openEvent(event._id);
              }}
            >
              <div className="title">
                {event.eventName}
              </div>
              <EventIconLabel
                firstView={event.firstView}
                eventName={event.like ? 'like' : event.eventName}
                includeLabel={false}
              />
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderDaysByMonth = useCallback(() => {
    console.log('FocusFeed: renderDaysByMonth');

    return Array.from(months.entries()).map(([monthKey, monthData]) => (
      <div className="month-container" data-month={monthKey} key={monthKey}>
        <div className={isThisMonth(monthKey, startTimestamp) ? 'month-title today' : 'month-title'}>
          {formatMonth(monthKey)}
        </div>
        {monthEvents?.get(monthKey) && renderMonthEvents(monthKey)}
        {monthData.map(({ day, events }) => (
          <React.Fragment key={day}>
            {dayContainer(day, events)}
          </React.Fragment>
        ))}
      </div>
    ));
  }, [months]);


  return (
    <>
      {demoMode && (
        <div className="demo-mode">
          <div className="demo-title">Tutorial</div>
          <div className="demo-skip" onClick={() => exitDemo(true)}>
            Skip <FontAwesomeIcon className="icon" icon={faChevronRight} />
          </div>
          <div className="demo-complete" ref={demoComplete}>
            <h2>You Got It!</h2>
            <div className="description">Now it's time for your schedule</div>
            <button className="schedule-button" onClick={exitDemo}>
              <FontAwesomeIcon className="icon" icon={faCalendarCheck} />
              Continue
            </button>
          </div>
        </div>
      )}

      {!demoMode &&
        <Navigation
          navRef={navRef}
          startTimestamp={startTimestamp}
          feedRef={feedContainerRef}
          currentDayRef={currentDayRef}
          days={days}
          months={months}
          scrollToToday={scrollToToday}
          scrollToDay={scrollToDay}
          resetFeedScroll={resetFeedScroll}
          disableFeedScroll={disableFeedScroll}
          handleNavigationIntro={handleNavigationIntro} />}

      {!demoMode && navComplete &&
        <CheckMessages />}

      <div
        ref={feedContainerRef}
        className={`feed-container${demoMode ? ' demo' : ''}`}
        onTouchStart={handleFeedTouchStart}
        onWheel={handleFeedTouchStart}
      >
        {(months && months.size > 0) &&
          renderDaysByMonth()}
      </div>
      <div ref={attentionMarkerRef} className="attention-marker" />
      <div className="notice">Check important dates. AI can make mistakes.</div>
    </>
  );
};

export default FocusFeed;
