import React from 'react';

const Join = () => {
  return (
  <div className='join'>
    <h2>Effortlessly know what's <br/>next for your kids</h2>
    <h2>Stress-free school and sports <br/>scheduling for you</h2>
    <button className='google-button' onClick={() => { window.location.href = '/api/auth/google'}}>Sign In</button>
  </div>
  );
};

export default Join;