import moment from 'moment';
import { extendMoment } from 'moment-range';

const extendedMoment = extendMoment(moment);

const formatTimeRange = (event) => {
  const eventMoment = getMomentRange(event);

  let displayTime = '\u00A0'; // Non-breaking space as a placeholder

  if (eventMoment) {
    if (extendedMoment.isRange(eventMoment)) {
      displayTime = `${formatTime(eventMoment.start)} - ${formatTime(eventMoment.end)}`; // EventMoment Object! Not event object!
    } else {
      displayTime = formatTime(eventMoment);
    }
  }

  return displayTime;
}

const formatEventDate = (dateString, hasTime) => {
    const utcDate = moment.utc(dateString);
    if (hasTime) {
        // For events with time precision, convert to local time
        return utcDate.local().format('YYYY-MM-DDTHH:mm:ss');
    } else {
        // For day-level precision events, keep the date as is
        return utcDate.format('YYYY-MM-DD');
    }
}

function getMomentRange(event) {
    if (!event.dateTime) {
      return null;
    }

    const startMoment = event.startLocal ? extendedMoment(event.startLocal) : null;
    const endMoment = event.endLocal ? extendedMoment(event.endLocal) : null;

    if (startMoment && endMoment) {
      return extendedMoment.range(startMoment, endMoment);
    }

    return startMoment;
  }

  function formatTime(momentTime) {
    if (momentTime.minutes() === 0) {
      return momentTime.format('ha');
    } else {
      return momentTime.format('h:mma');
    }
  }

  function formatMonth(YYYYMM, startTimestamp) {
    const checkYear = moment(YYYYMM).format('YYYY');
    const thisYear = moment(startTimestamp).format('YYYY');

    if (YYYYMM) {
      return (YYYYMM.slice(-2) === '01' || YYYYMM.slice(-2) === '12' || checkYear != thisYear) // Year added to months not in current year
        ? moment(YYYYMM).format('MMMM YYYY')
        : moment(YYYYMM).format('MMMM')
    }
  }

  const isToday = (day, startTimestamp) => {
    return moment(day).isSame(startTimestamp, 'day') ? 'today' : ''
  }

  function isThisMonth(date, startTimestamp) {
    if (date && startTimestamp) {
      const checkMonth = moment(date).format('YYYY-MM');
      const thisMonth = moment(startTimestamp).format('YYYY-MM');
      return checkMonth.toString() === thisMonth.toString();
    }
    return false;
  }

export { formatEventDate, getMomentRange, formatTime, formatMonth, isThisMonth, isToday, formatTimeRange }
